























































































































import Vue from 'vue'
import Chart from './Chart.vue'
import saveAs from 'file-saver'
import { getNowDateTime, stringifyDate } from '@/helpers/dates'
import Button from '@/components/ui/Button/Button.vue'
import Loader from '@/components/ui/Loader/Loader.vue'
import { WeatherStationData } from '@/store/weatherstation/definitions'
import { defaultBeginningSets, getDatasets } from './helper'

export default Vue.extend({
  name: 'WeatherStation',
  components: {
    Chart,
    'ui-button': Button,
    'ui-loader': Loader
  },
  data: () => ({
    datasetTemperature: null,
    datasetWind: null,
    datasetHuPressure: null,
    datasetRain: null,
    datasetWindDirection: {
      x: [],
      y: []
    },
    datasetWindRoseDirection: null,
    minTemperature: null,
    maxTemperature: null,
    minWind: null,
    maxWind: null,
    minHumidity: null,
    maxHumidity: null,
    minPressure: null,
    maxPressure: null,
    maxRain: null,
    rainIntensityTotal: 0,
    pressureAddition: 5,
    windAddition: 5,
    temperatureAddition: 5,
    humidityAddition: 1
  }),
  computed: {
    reversedStationData () {
      if (!this.stationData) return []
      return this.stationData.slice().sort((a, b) => {
        if (a.time > b.time) return -1
        return 1
      })
    }
  },
  props: {
    station: { default: () => ({}) },
    stationData: { default: () => ([]) },
    loading: {
      type: Boolean,
      default: false
    },
    detailUrl: {
      type: Object
    },
    dashboardUrl: {
      type: Object
    },
    displayDetail: {
      type: Boolean
    },
    displaySession: {
      type: Boolean,
      default: true
    },
    sessions: {
      default: () => ([])
    },
    selectedRangeLocal: {
      default: () => ([])
    }
  },
  methods: {
    exportAsCSV () {
      const csvData = []
      csvData.push(
        'Date Time,' +
        'Time,' +
        'Air Temperature,' +
        'Track Temperature,' +
        'Humidity,' +
        'Pressure,' +
        'Wind Direction,' +
        'Wind Average Speed,' +
        'Gusts,' +
        'Rain\n'
      )
      this.stationData.forEach(currentData => {
        csvData.push(
          currentData.timeFormatted + ',' +
          currentData.timeToDisplay + ',' +
          currentData.airTemperature + ',' +
          currentData.trackTemperature + ',' +
          currentData.humidity + ',' +
          currentData.pressure + ',' +
          currentData.windDirectionDegree + ',' +
          currentData.windSpeed + ',' +
          currentData.gusts + ',' +
          currentData.rainIntensity + '\n'
        )
      })
      const blob = new Blob(csvData, { type: 'text/plain;charset=utf-8' })
      const now = stringifyDate(getNowDateTime())
      saveAs(blob, now + '_weatherstation_' + this.station.properties.reference + '.csv')
    },
    resetData () {
      Object.keys(defaultBeginningSets).forEach(key => {
        this[key] = defaultBeginningSets[key]()
      })
    }
  },
  watch: {
    stationData: {
      immediate: true,
      handler (newData: WeatherStationData[]) {
        const datasets = getDatasets(newData)
        datasets && Object.keys(datasets).forEach(key => {
          this[key] = datasets[key]
        })
      }
    }
  }
})
